import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLDialogElement> {
  connect(): void {
    this.showModal();
  }

  open(event: Event) {
    event.preventDefault();
    this.showModal();
  }

  showModal() {
    this.element.showModal();
    this.element.addEventListener("click", (e) => this.backdropClick(e));
  }

  close(event: Event) {
    event.preventDefault();
    this.element.close();
  }

  backdropClick(event: Event) {
    event.target === this.element && this.close(event);
  }
}
