import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLElement> {
  static values = {
    timeout: Number,
  };

  declare timeoutValue: number;

  connect(): void {
    this.element.classList.remove("hidden");
    this.element
      .animate(
        [
          { opacity: 0, transform: "translateX(100%)" },
          { opacity: 1, transform: "translateX(0)" },
        ],
        { duration: 700, easing: "ease" }
      )
      .finished.then(() => {
        if (this.timeoutValue > 0) {
          setTimeout(() => {
            this.close();
          }, this.timeoutValue);
        }
      });
  }

  close() {
    this.element
      .animate(
        [
          { opacity: 1, transform: "translateX(0)" },
          { opacity: 0, transform: "translateX(100%)" },
        ],
        { duration: 500, easing: "ease" }
      )
      .finished.then(() => {
        this.element.remove();
      });
  }
}
